.goldenBtn {
    height: auto;
}

.goldenBtn:hover {
    transform: scale(1.2);

}

.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);

}

.modal-content {
    background-color: #1b1a1a;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    
}

.close {
    color: var(--fontColor);
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}



.projectBtn {
 background-color: var(--fontColor) !important;
 color: white !important;
}

/* Style for the custom arrows */
.slick-arrow {
    font-size: 30px; /* Larger arrows */
    color: #F5A623; /* Orange color for arrows */
    cursor: pointer;
    z-index: 1000; /* Ensure arrows appear above other elements */
  }
  
  .slick-prev,
  .slick-next {
    background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent background for better contrast */
    padding: 10px;
    border-radius: 50%; /* Round background */
  }
  
  .slick-prev {
    left: -50px; /* Position the previous arrow on the left */
  }
  
  .slick-next {
    right: -50px; /* Position the next arrow on the right */
  }
  
  .slick-prev:hover,
  .slick-next:hover {
    color: #ff9800; /* Hover color */
    background-color: rgba(0, 0, 0, 0.6); /* Darken the background on hover */
  }
  
  /* Optional: Add a subtle shadow for better visibility */
  .slick-prev, .slick-next {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  
  .NewModal {
    color: #ff9800;
  }

  .modal-content {
  animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.modal-content {
  background-color: #F5A623; /* Golden background */
  border-radius: 12px;
  max-width: 500px;
  width: 90%;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow-y: auto;
  max-height: 90vh;
  
}

.modal-content img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 8px;
  display: block;
  margin: 0 auto 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .modal-content {
    padding: 15px;
    max-width: 90%;
  }

  .modal-content h2 {
    font-size: 1.2rem;
  }

  .modal-content p {
    font-size: 0.9rem;
  }

  .modal-content button {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}
