.heroGrid {
    background-image: url(../products/assets/hero.jpg);
    background-size: cover; /* Ensures the image is scaled down */
    background-position: center; /* Centers the image */
    background-repeat: no-repeat; /* Prevents the image from repeating */
    width: 100%; /* Set the width to cover the entire container */
    box-shadow: inset 0 0em 1em var(--fontColor);
    padding: 20px;
    margin: 5px;
    height: 600px; /* Reduce height by 40% */
    position: relative; /* Required for positioning the credit text */
}

.heroGrid::after {
    content: "Credit: WhiskyFoundation_Jo Hanley"; /* Add the credit text */
    position: absolute;
    bottom: 10px; /* Position it at the bottom of the container */
    right: 10px; /* Align it to the right */
    font-size: 12px; /* Adjust font size */
    color: #808080; /* Subtle white color */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for better readability */
    padding: 5px 10px; /* Add some padding for the text */
    border-radius: 5px; /* Round the corners */
    font-family: Arial, sans-serif; /* Match the design aesthetic */
}


/* Ensure text visibility */
.heroContent {
    color: rgb(215, 241, 149) !important; /* Ensures the text is white for better contrast */

    font-family: Georgia, 'Times New Roman', Times, serif;
}

/* Media query for small screens */
@media (max-width: 600px) {
    .heroGrid {
        height: 150px; /* Further reduced height for small screens */
    }
}

