

footer {
    font-family: 'Roboto', sans-serif;
  }
  
  footer a {
    color: inherit;
    text-decoration: none;
  }
  
  footer a:hover {
    color: #f5a623; /* Hover effect for links */
  }
  
  footer .social-icons button:hover {
    transform: scale(1.1); /* Slight zoom on hover */
    transition: transform 0.2s;
  }
  

