.aboutUs {
    padding: 10px;
    max-width: 100%;
}

.divider {
    border-color: var(--fontColor) !important;
    border-bottom-width: 3px;
    
}
