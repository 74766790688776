img {
    height: 60px;
}

.navigation {
    background-color: var(--mainColor) !important;
}

.navLink {
    color: var(--fontColor) !important;
    font-weight: bolder !important; 
}

