/* Container Styling */
.whisky-container {
    font-family: "Roboto", sans-serif;
    color: #f4f4f4;
    background-color: #2c2c2c;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 10px;
  }
  
  /* Header */
  .whisky-header {
    text-align: center;
    padding: 40px 20px;
    background-color: #f5a623;
    color: #2c2c2c;
    border-bottom: 3px solid #d1d1d1;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .whisky-header:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
  
  .whisky-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.3;
  }
  
  .whisky-congratulations {
    font-size: 1.2rem;
    margin-top: 15px;
    line-height: 1.6;
    max-width: 800px;
    margin: 20px auto 0;
    color: #ffffff;
  }
  
  /* Section */
  .whisky-section,
  .whisky-highlight-section {
    background-color: #3c3c3c;
    border-radius: 10px;
    margin: 20px auto;
    max-width: 800px;
    padding: 20px 30px;
    text-align: center;
    transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  
  .whisky-highlight-section {
    background-color: #1f1f1f;
  }
  
  .whisky-section:hover,
  .whisky-highlight-section:hover {
    transform: scale(1.05);
    background-color: #474747;
  }
  
  .whisky-section-title {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #f5a623;
  }
  
  .whisky-section-text {
    font-size: 1.1rem;
    line-height: 1.6;
    font-weight: 300;
    color: #d1d1d1;
  }
  
  .highlight {
    color: #f5a623;
  }
  
  /* Footer */
  .whisky-footer {
    text-align: center;
    background-color: #2c2c2c;
    color: #d1d1d1;
    padding: 20px;
    font-size: 0.9rem;
    transition: color 0.3s ease;
  }
  
  .whisky-footer:hover {
    color: #f5a623;
  }
  